#competences-page {
    position: relative;
    background-color: #e63946;
}

#main-circle {
    height: 150px;
    width: 150px;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border: 3px solid #f1faee;
    padding: 6px;
    background-color: #e63946;
    box-shadow: 0 0 10px 0px #0007;
}

.skill {
    position: absolute;
    transition: transform 3s ease-in;
    z-index: 1;

    &:hover {
        z-index: 2;
    }

    & > div {
        height: 100%;
        width: 100%;
        background: #f1faee;
        transition: transform 3s ease-out, height .2s ease-out, width .2s ease-out;
        padding: 8px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 10px 0px #0007;
        border-radius: 16px;
        position: absolute;
        
        &:hover {
            width: 300px;
            height: 500px;
            & img {
                height: 100px;
            }
        }
        
        & img {
            transition: all .2s ease-out;
            border-radius: 20%;
            margin: 0 auto;
        }

        & > .title {
            margin-top: 0;
            font-size: xx-large;
            text-align: center;
            margin: 16px;
            display: none;
            &.visible {
                display: block;
                animation: fadeInLeft .4s ease-out;
            }
        }

        & > .description {
            font-size: larger;
            text-align: center;
            margin: auto;
            display: none;
            white-space: pre-line;
            &.visible {
                display: block;
                animation: fadeInBottom .4s ease-out backwards;
                animation-delay: .2s;
            }
        }
    }
}

.line {
    position: absolute;
    background-color: #f1faee;
    border-top: 3px solid white;
    padding: 0;
    margin: 0;
    z-index: 0;
    box-shadow: 0 0 10px 0px #0007;
    // animation: fadeIn 1s;
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-150px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes fadeInBottom {
    0% {
        opacity: 0;
        transform: translateY(150px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}