@import '../../utils/styles.scss';
@import '../../colorDefinitions.scss';

@media screen and (max-width: 900px) {
    #about-page {
        justify-content: space-evenly !important;
    }

    #scrolldown-btn {
        position: relative !important;
        margin: 0 auto !important;
        bottom: auto !important;
        left: auto !important;
    }
}

#about-page {
    display: flex;
    flex-direction: column;
    background-color: #1d3557;
    justify-content: center;
    position: relative;
}

#cv-download-btn {
    $btnColor: #457b9d;
    $transition: all 0.25s cubic-bezier(0.310, -0.105, 0.430, 1.400);

    user-select: none;
    font-size: large;
    margin: 0 auto;
    margin-top: 64px;
    color: #f1faee;
    height: 52px;
    width: 300px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    background: $btnColor;
    position: relative;
    cursor: pointer;
    transition: $transition;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0 #0007;
    overflow: hidden;
    line-height: 0;
    
    & #icon { 
        transition: $transition;
        position: absolute;
        left: 1.4em;
        font-size: 1.115em;
        margin-top: -2px;
    }
    
    & #text {
        position: absolute;
        transition: $transition;
        top: 50%;
        left: 60%;
        transform: translate(-50%, -50%);
        letter-spacing: 1px;
        width: 220px;
    }
    &:hover {
        background: lighten($btnColor, 5%);
        & #text {
            transform: translate(500px, 0);
        }
        & #icon {
            left: 50%;
            transform: translate(-50%, 0) 
            scale(1.5);
        }
    }
}

#scrolldown-btn {
    height: 50px;
    width: 30px;
    border: 3px solid color(secondary);
    margin: 0 auto;
    margin-top: 64px;
    border-radius: 50px;
    position: absolute;
    bottom: 5em;
    left: 50%;

    &:before {
        content: " ";
        border-radius: 5px;
        height: 10px;
        width: 6px;
        background-color: color(secondary);
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translate(-50%, 0);
        animation: scrolldown 2s infinite;
        box-shadow: 0px -5px 3px 1px #ffffff66;
    }

    & .chevron {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0) rotate(-45deg);
        border-bottom: 3px solid color(secondary);
        border-left: 3px solid color(secondary);
        height: 20px;
        width: 20px;
        animation: pulse 1s infinite alternate;
        
        &:nth-child(1) {
            bottom: -50%;
        }
        
        &:nth-child(2) {
            animation-delay: .2s;
            bottom: -70%;
        }

        &:nth-child(3) {
            animation-delay: .4s;
            bottom: -90%;
        }
    }
}

@keyframes scrolldown {
    0% {
        height: 3px;
        opacity: 0;
        transform: translate(-50%, 0);
    }
    40% {
        opacity: 1;
        height: 10px;
    }
    100% {
        height: 10px;
        opacity: 0;
        transform: translate(-50%, 20px);
    }
}

@keyframes pulse {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}